import {store} from "../../../../../store/index";
import Api from "../../../../../store/Services/Api";

class PayOutService{
    getPayOutLists(size){
        const url = `api/account/pay-out/size/${size}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    getPayOutElements(){
        const url = `api/account/pay-out/create/elements`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    getReceiptNoByBranch(branch){
        const url = `api/account/pay-out/receipt-no/${branch}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    createNewPayOut(formdata){
        const url = `api/account/pay-out/store`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,formdata,headers);
    }
    cancelPayOut(id,remarks){
        const url = `api/account/pay-out/cancel/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,remarks,headers);
    }
    getPayOutFilteredDatas(param){
        const url = `api/account/pay-out/filter/all`
        const params = {
            'page-index': param.size,
            'from':param.from,
            'to':param.to,
            'account_type':param.account_type,
            'searched':param.searched,
            'branch':param.branch,
            'offset':param.offset,
            'status':param.status,
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
}
export default new PayOutService();