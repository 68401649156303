<template>
  <div class="content-body">
    <div class="row mg-0 mt-2 mt-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Account</a></li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Payment Voucher
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Payment Vouchers</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 tx-right">
        <button
          type="button"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="
            $store.dispatch('modalWithMode', { id: 'newPayOut', mode: 'create' })
          "
        >
          New Payment
        </button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0 no-print">
      <div class="row">
        <div class="filter-input col-md-10 d-block mr-0 pr-0">
          <select v-model="params.size" @change="filterDatas" class="mg-t-5 mr-2" id="pays_out_filter_size">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <select v-model="params.branch" class="filter-input mg-t-5 mr-2" id="pays_out_filter_branch_type" @change="filterDatas">
            <option value="">All Branch</option>
            <option v-for="(branch,index) in payOutElements.filter_branchs" :value="branch.id" :key="index">{{branch.name}}</option>
          </select>
          <select v-model="params.account_type" class="filter-input mg-t-5 mr-2" id="pays_out_filter_account_type" @change="filterDatas">
            <option value="">All Account Type</option>
            <option v-for="(type,index) in payOutElements.account_types" :key="index" :value="type.id">{{type.title}}</option>
          </select>
           <select v-model="params.status" class="filter-input mg-t-5 mr-2" id="pays_out_filter_status" @change="filterDatas">
            <option value="">All Status</option>
            <option value="1">Canceled</option>
            <option value="0">Not Canceled</option>
          </select>
          <div class="mr-2 d-inline-block">
            <label class="filter-lavel mb-0 mg-t-5 mr-1">From</label>
            <input type="date" v-model="params.from" placeholder="From" class="filter-input mg-t-5 mr-1" id="pays_out_filter_date_from" @change="filterDatas">
            <label class="filter-lavel mb-0 mg-t-5 mr-1">To</label>
            <input type="date" v-model="params.to" placeholder="To" class="mr-1 mg-t-5 filter-input" id="pays_out_filter_date_to" @change="filterDatas">
          </div>
        </div>
        <div class="col-md-2 mr-0 mg-t-4 pl-0">
          <input type="text" v-model="params.searched"  class="float-right" id="pays_out_search" placeholder="Search..." @keyup="filterDatas">
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Date</th>
              <th class="wd-10p">Voucher No</th>
              <th class="wd-10p">Account Type</th>
              <th class="wd-20p">Account Name</th>
              <th class="wd-15p">Payment Mode</th>
              <th class="wd-10p">Paid Amt.</th>
              <th class="wd-10p">Due Amt.</th>
              <th class="wd-10p text-center table-end-item no-print" v-if="checkIfAnyPermission(['view_pay_out','cancel_pay_out'])">Action</th>
            </tr>
          </thead>
          <tbody v-if="!loading && payOutDatas.length > 0">
            <tr v-for="(pays,index) in payOutDatas" :key="index">
              <th scope="row" class="table-start-item">{{pageSerialNo+index}}.</th>
              <td>{{pays.date}}</td>
              <td>{{pays.receipt_no}} <span class="tx-danger" v-if="pays.status == 1">-Canceled </span></td>
              <td>{{pays.account_type_name}} </td>
              <td >{{pays.name}} <span class="tx-primary" v-if="pays.type ==1">- Advance</span></td>
              <!-- <td v-if="pays.account_type.slug == 'customer'">{{pays.account_type_name}} <span class="tx-primary" v-if="pays.type ==1">- Advance</span></td> -->
              <td>{{pays.payment_title}} <span v-if="pays.bank!= null">-{{pays.bank_name}}</span></td>
              <td>{{parseDigitForSlip(pays.paid_amount)}}</td>
              <td >{{parseDigitForSlip(pays.due_amount)}} </td>
              <td class="text-right table-end-item no-print" v-if="checkIfAnyPermission(['view_pay_out','cancel_pay_out'])">
                <a href="javascript:;" class="mr-3" :id="'payOutView'+pays.id" v-if="checkSinglePermission('view_pay_out')" title="View Slip">
                  <i class="fas fa-eye tx-success"  @click="view('receiptSlip',pays.id)"></i>
                </a>
                <a href="javascript:;" class="mr-3" :id="'payOutCancel'+pays.id" v-if="pays.status==0 && checkSinglePermission('cancel_pay_out')" title="Cancel Pay">
                  <i class="fas fa-times-circle tx-danger"  @click="cancel(pays.id)"></i>
                </a>
              </td>
            </tr>
          </tbody>
           <tbody v-else-if="error">
            <tr>
              <td  colspan="11" style="text-align:center;">
               {{error_message}}
              </td>
            </tr>
          </tbody>
            <tbody v-else-if="!loading && payOutDatas.length == 0">
            <tr>
              <td  colspan="11" style="text-align:center;">
                No Records Found.
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td  colspan="11" style="text-align:center;">
                Loading.....
              </td>
            </tr>
          </tbody>
         
        </table>
        <Paginate v-model="page" :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1" />
        <ExpenseCreate @parent-event="getData()"/>
        <ReceiptSlip />
      </div>
    </div>
  </div>
</template>
<script>
import ExpenseCreate from "./create";
import ReceiptSlip from './receiptSlip';
import {mapGetters} from "vuex";
//import {Search} from "../../../../../mixins/search";
import Paginate from 'vuejs-paginate'
import Services from "./Services/Services";
import _ from 'lodash';
export default {
 //mixins: [Search],
  data(){
    return{
      params :{
        'size':10,
        'branch':'',
        'account_type':'',
        'today':'',
        'month':'',
        'from':'',
        'searched':'',
        'to':'',
        'offset':0,
        'status':'',
      },
      loading:true,
      error:false,
      //pagination required
      totalPageCount:0,
      pageReload:'',
      pageSerialNo:1,
    }
  },
  components: {
    ExpenseCreate, ReceiptSlip, Paginate
  },
   computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists2",
      "removeList",
      "pagination",
      "authData",
      "today",
      "eventMessage",
    ]),
    ...mapGetters("payouts",["payOutDatas","payOutElements"])
  },
  mounted() {
    this.getData();
  },
  methods: {
    //pagination method
    clickCallback: function(page) {
      if(page == 1){
        this.pageSerialNo = 1;
      }else{
        this.pageSerialNo = ((page-1) * this.params.size) + 1 ;
      }
      this.params.offset = (page-1) * this.params.size;
      this.getData();
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    view(modal,id){
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal,mode:"read"});
    },
    filterDatas: _.debounce(function(){
      this.page = 1;
      this.pageSerialNo = 1;
      this.params.offset = 0;
      this.loading = true;
      this.disableSubmitButton=true;
      Services.getPayOutFilteredDatas(this.params).then(res=>{
          this.$store.commit("payouts/setPayOutDatas",res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.loading=false;
          //this.setNotification(res.data.data.success_message);
      }).catch(err=>{
        if (err.response.data.error_message == "Access Denied.") {
                this.error_message = "Access Denied !!!";
              } else {  
                this.error_message = "Error Fetching data from the server.";
              }
          this.loading=false;
          this.error = true;
          let error = err.response.data.error_message;
          this.setNotification(error);
      });
    },1000),
    cancel(id) {
      this.$swal({
       title: 'Do you really want to cancel this Pay-Out ?',
         html:
        `<label>Remarks: </label>
        <textarea id="cancel-remarks" class="swal2-input"></textarea>
       `,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Cancel it!',
        cancelButtonText: 'No',
        showLoaderOnConfirm: true,
        preConfirm:() => {
          let remarks = document.getElementById('cancel-remarks').value;
          // cancel the payin with remarks 
           return Services.cancelPayOut(id,{'remarks':remarks}).then((response) => {   
                this.payOutDatas.forEach((item,index) => {
                  if(item.id == id){
                      this.payOutDatas[index]['status'] = 1;
                      this.payOutDatas[index]['cancel_username'] = this.authData.user.name;
                      this.payOutDatas[index]['cancel_date'] = this.today;
                      this.payOutDatas[index]['remarks'] = remarks;
                  }         
                });
          if(response.status == 200){
              let success = response.data.success_message;
              this.setNotification(success);
            }
          }).catch(error => { 
               this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if(error.response.status == 500){
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
          })
        }
      })
    },
    setNotification(msg){
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",msg);
    },
    getData(){
      this.loading =true;
      Services.getPayOutFilteredDatas(this.params).then(res=>{
          this.$store.commit("payouts/setPayOutDatas",res.data.data.data);
          this.getPayOutElements();
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.loading= false;
      }).catch(err=>{
         if (err.response.data.error_message == "Access Denied.") {
                this.error_message = "Access Denied !!!";
              } else {  
                this.error_message = "Error Fetching data from the server.";
              }
          this.loading=false;
          this.error = true;
      })
    },
    getPayOutElements() {
      Services.getPayOutElements()
        .then((res) => {
          this.$store.commit("payouts/setPayOutElements", res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  }, 
  watch: {
    // eventMessage(value) {
    //   if (value.indexOf("deleted success") >= 0) {
    //     this.$store.commit("setRemoveList", this.dataId);
    //   }
    // },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  }
};
</script>